<div class="card rounded shadow p-4 border-0">
  <form class="needs-validation">
    <div class="row">
        <div class="col-md-12">
            <h5>Pick a Richardson Monument location nearest your customer.</h5>
        </div>
        <div class="col-md-12" style="margin-top: 20px;border-top: 1px solid lightgrey;"><h6>MAINE</h6></div>
        <div class="col-md-6">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MESouthPortland" value="ME - South Portland" [(ngModel)]="location">
                    <label class="form-check-label" for="MESouthPortland">South Portland</label>
                </div>
            </div>  
        </div>
        <div class="col-md-6">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MEBiddeford" value="ME - Biddeford" [(ngModel)]="location">
                    <label class="form-check-label" for="MEBiddeford">Biddeford</label>
                </div>
            </div>   
        </div>      

        <div class="col-md-12" style="margin-top: 20px;border-top: 1px solid lightgrey;"><h6>NEW HAMPSHIRE</h6></div>
        <div class="col-md-6">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="NHRochester" value="NH - Rochester" [(ngModel)]="location">
                    <label class="form-check-label" for="NHRochester">Rochester</label>
                </div>
            </div>    
        </div>
        <div class="col-md-6">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="NHPortsmouth" value="NH - Portsmouth" [(ngModel)]="location">
                    <label class="form-check-label" for="NHPortsmouth">Portsmouth</label>
                </div>
            </div>    
        </div>

    </div>
    <button class="w-100 btn btn-primary" style="margin-top: 20px;" type="submit" [disabled]="!location" (click)="updateLocation()">NEXT</button>
    <!--end row-->
  </form>
  <!--end form-->
</div>