import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core'; // Or MatMomentDateModule if using Moment.js
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountToModule } from 'angular-count-to';
import { NgxMasonryModule } from 'ngx-masonry';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from "./shared/shared.module";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// Apex chart
// import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FeatherModule } from 'angular-feather';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PageErrorComponent } from './core/components/page-error/page-error.component';
import { CookieService } from 'ngx-cookie-service';

// angular material
import { ContactSouthPortlandMeComponent } from './core/components/contact-south-portland-me/contact-south-portland-me.component';
import { ContactBiddefordMeComponent } from './core/components/contact-biddeford-me/contact-biddeford-me.component';
import { ContactRochesterNhComponent } from './core/components/contact-rochester-nh/contact-rochester-nh.component';
import { ContactPortsmouthNhComponent } from './core/components/contact-portsmouth-nh/contact-portsmouth-nh.component';

import { GalleryMonumentUprightComponent } from './core/components/gallery-monument-upright/gallery-monument-upright.component';
import { IndexRichardsonMonumentComponent } from './core/components/index-richardson-monument/index-richardson-monument.component';
import { PageCemeteriesHomeComponent } from './core/components/page-cemeteries-home/page-cemeteries-home.component';
import { GalleryMonumentFlatComponent } from './core/components/gallery-monument-flat/gallery-monument-flat.component';
import { GalleryMonumentBenchComponent } from './core/components/gallery-monument-bench/gallery-monument-bench.component';
import { GalleryMonumentCremationComponent } from './core/components/gallery-monument-cremation/gallery-monument-cremation.component';
import { GalleryMonumentBronzeComponent } from './core/components/gallery-monument-bronze/gallery-monument-bronze.component';
import { GalleryMonumentCustomComponent } from './core/components/gallery-monument-custom/gallery-monument-custom.component';
import { GalleryMonumentCivicJobsComponent } from './core/components/gallery-monument-civic-jobs/gallery-monument-civic-jobs.component';
import { GalleryMonumentMausoleumsComponent } from './core/components/gallery-monument-mausoleums/gallery-monument-mausoleums.component';
import { GalleryMonumentOnsiteCleaningComponent } from './core/components/gallery-monument-onsite-cleaning/gallery-monument-onsite-cleaning.component';
import { GalleryMonumentOnsiteEngravingComponent } from './core/components/gallery-monument-onsite-engraving/gallery-monument-onsite-engraving.component';
import { PageBookAppointmentComponent } from './core/components/page-book-appointment/page-book-appointment.component';

import { ContactUsService } from './services/contact-us.service';
import { CemeteryService } from './services/cemetery.service';
import { FileUploadService } from './services/file-upload.service';
import { GalleryMonumentSlantComponent } from './core/components/gallery-monument-slant/gallery-monument-slant.component';
import { ContactUsHomeComponent } from './core/components/contact-us-home/contact-us-home.component';
import { ReferralInHomeComponent } from './core/components/referral-in-home/referral-in-home.component';
import { ReferralContactComponent } from './core/components/forms/referral-contact/referral-contact.component';
import { ReferralLocationComponent } from './core/components/forms/referral-location/referral-location.component';
import { ReferralCustomerComponent } from './core/components/forms/referral-customer/referral-customer.component';
import { ReferralFinalizeComponent } from './core/components/forms/referral-finalize/referral-finalize.component';
import { ReferralCemeteryComponent } from './core/components/forms/referral-cemetery/referral-cemetery.component';
import { FileUploaderComponent } from './core/components/file-uploader/file-uploader.component';
import { PageBookAppointmentFormComponent } from './core/components/page-book-appointment-form/page-book-appointment-form.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MasterPageComponent,
    PageErrorComponent,
    ContactSouthPortlandMeComponent,
    ContactBiddefordMeComponent,
    ContactRochesterNhComponent,
    ContactPortsmouthNhComponent,
    GalleryMonumentUprightComponent,
    IndexRichardsonMonumentComponent,
    PageCemeteriesHomeComponent,
    GalleryMonumentFlatComponent,
    GalleryMonumentBenchComponent,
    GalleryMonumentCremationComponent,
    GalleryMonumentBronzeComponent,
    GalleryMonumentCustomComponent,
    GalleryMonumentCivicJobsComponent,
    GalleryMonumentMausoleumsComponent,
    GalleryMonumentOnsiteCleaningComponent,
    GalleryMonumentOnsiteEngravingComponent,
    PageBookAppointmentComponent,
    GalleryMonumentSlantComponent,
    ContactUsHomeComponent,
    ReferralInHomeComponent,
    ReferralContactComponent,
    ReferralLocationComponent,
    ReferralCustomerComponent,
    ReferralFinalizeComponent,
    ReferralCemeteryComponent,
    FileUploaderComponent,
    PageBookAppointmentFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], {}),
    NgxYoutubePlayerModule,
    NgbDropdownModule,
    CKEditorModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxUsefulSwiperModule,
    // NgApexchartsModule,
    MatMomentDateModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    NgxMasonryModule,
    LightboxModule,
    SharedModule,
    SimplebarAngularModule,
    HttpClientModule
  ],
  exports: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
  	ContactUsService, CemeteryService, CookieService, FileUploadService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
