import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { combineLatest } from 'rxjs/operators';

// INDEX
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { IndexRichardsonMonumentComponent } from './core/components/index-richardson-monument/index-richardson-monument.component';
// ME
import { ContactSouthPortlandMeComponent } from './core/components/contact-south-portland-me/contact-south-portland-me.component';
import { ContactBiddefordMeComponent } from './core/components/contact-biddeford-me/contact-biddeford-me.component';
// NH
import { ContactRochesterNhComponent } from './core/components/contact-rochester-nh/contact-rochester-nh.component';
import { ContactPortsmouthNhComponent } from './core/components/contact-portsmouth-nh/contact-portsmouth-nh.component';

// galleries
import { GalleryMonumentUprightComponent } from './core/components/gallery-monument-upright/gallery-monument-upright.component';
import { GalleryMonumentFlatComponent } from './core/components/gallery-monument-flat/gallery-monument-flat.component';
import { GalleryMonumentBenchComponent } from './core/components/gallery-monument-bench/gallery-monument-bench.component';
import { GalleryMonumentBronzeComponent } from './core/components/gallery-monument-bronze/gallery-monument-bronze.component';
import { GalleryMonumentCivicJobsComponent } from './core/components/gallery-monument-civic-jobs/gallery-monument-civic-jobs.component';
import { GalleryMonumentCremationComponent } from './core/components/gallery-monument-cremation/gallery-monument-cremation.component';
import { GalleryMonumentCustomComponent } from './core/components/gallery-monument-custom/gallery-monument-custom.component';
import { GalleryMonumentMausoleumsComponent } from './core/components/gallery-monument-mausoleums/gallery-monument-mausoleums.component';
import { GalleryMonumentOnsiteCleaningComponent } from './core/components/gallery-monument-onsite-cleaning/gallery-monument-onsite-cleaning.component';
import { GalleryMonumentOnsiteEngravingComponent } from './core/components/gallery-monument-onsite-engraving/gallery-monument-onsite-engraving.component';
import { GalleryMonumentSlantComponent } from './core/components/gallery-monument-slant/gallery-monument-slant.component';

// cemetery pages
import { PageCemeteriesHomeComponent } from './core/components/page-cemeteries-home/page-cemeteries-home.component';
import { PageBookAppointmentComponent } from './core/components/page-book-appointment/page-book-appointment.component';
import { ContactUsHomeComponent } from './core/components/contact-us-home/contact-us-home.component';
// OTHER PAGES
import { ReferralInHomeComponent } from './core/components/referral-in-home/referral-in-home.component';
import { PageBookAppointmentFormComponent } from './core/components/page-book-appointment-form/page-book-appointment-form.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
	    { path: '', component: IndexRichardsonMonumentComponent },
	    { path: 'index', component: IndexRichardsonMonumentComponent },
	    { path: 'contact-south-portland-me', component: ContactSouthPortlandMeComponent },
		  { path: 'contact-biddeford-me', component: ContactBiddefordMeComponent },
		  { path: 'contact-rochester-nh', component: ContactRochesterNhComponent },
		  { path: 'contact-portsmouth-nh', component: ContactPortsmouthNhComponent },
		  { path: 'search-cemetery-home', component: PageCemeteriesHomeComponent },
		  { path: 'page-book-appointment', component: PageBookAppointmentComponent },
		  { path: 'referral-in-home', component: ReferralInHomeComponent },
		  { path: 'gallery-monument-upright', component: GalleryMonumentUprightComponent },
		  { path: 'gallery-monument-flat', component: GalleryMonumentFlatComponent },
		  { path: 'gallery-monument-bench', component: GalleryMonumentBenchComponent },
		  { path: 'gallery-monument-bronze', component: GalleryMonumentBronzeComponent },
		  { path: 'gallery-monument-civic-jobs', component: GalleryMonumentCivicJobsComponent },
		  { path: 'gallery-monument-cremation', component: GalleryMonumentCremationComponent },
		  { path: 'gallery-monument-custom', component: GalleryMonumentCustomComponent },
		  { path: 'gallery-monument-mausoleums', component: GalleryMonumentMausoleumsComponent },
		  { path: 'gallery-monument-onsite-cleaning', component: GalleryMonumentOnsiteCleaningComponent },
		  { path: 'gallery-monument-onsite-engraving', component: GalleryMonumentOnsiteEngravingComponent },
		  { path: 'gallery-monument-slant', component: GalleryMonumentSlantComponent },
		  { path: 'contact-us-home', component: ContactUsHomeComponent },
		  { path: 'book-now/:loc', component: PageBookAppointmentFormComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
