import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us-home',
  templateUrl: './contact-us-home.component.html',
  styleUrls: ['./contact-us-home.component.css']
})
export class ContactUsHomeComponent implements OnInit {
  
  @ViewChild('targetDiv') targetDiv!: ElementRef<HTMLDivElement>;
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';

  showMaine = false;
  showNH = false;

  constructor(
  ) {}

  ngOnInit(): void {
  }

  filterLocations(loc:string) {
    switch ( loc ) {
       case 'me':
            this.showMaine = true;
            this.showNH = false;
           break;
       case 'ma':
            this.showMaine = false;
            this.showNH = false;
           break;
       case 'nh':
            this.showMaine = false;
            this.showNH = true;
           break;
       default: 
            this.showMaine = false;
            this.showNH = false;
           break;
    }
    this.targetDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });   
  }
  bookAppointment(loc:string) {
    switch(loc){     
      case "zoom":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/LCY16T44NNQ2X/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;    
      default:
        break;
    }
  }


}
