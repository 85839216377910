<!-- Start -->
<section class="section">
  <div class="container">



    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="features-absolute">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div (click)="filterLocations('me')" class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="cursor: pointer;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">MAINE</a></h5>
                  <p class="text-muted">2 - Locations</p>

                  <a href="javascript:void(0)" class="text-primary">VIEW LOCATIONS<i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div (click)="filterLocations('nh')" class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="cursor: pointer;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">NEW HAMPSHIRE</a></h5>
                  <p class="text-muted">2 - Locations</p>

                  <a href="javascript:void(0)" class="text-primary">VIEW LOCATIONS<i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-12 col-md-12 col-12" style="margin-top:40px;cursor: pointer;" (click)="bookAppointment('zoom')">
              <div class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="background-color: #7F1517;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-light">CANT MAKE IT IN PERSON. NO PROBLEM</a></h5>
                  <p class="text-light">Schedule a virtual design meeting via Zoom, <img src="assets/images/Zoom-Logo.png"> with one of our reps by clicking below.</p>
                  <a href="javascript:void(0)" class="text-light">SCHEDULE HERE<i class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->            

          </div>
        </div>
      </div>
    </div>


    <div class="row projects-wrapper" #targetDiv style="margin-top: 40px;">
      <!-- MAINE -->
      <ng-container *ngIf="showMaine">
            <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2 designing">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MAINE - <strong>Biddeford</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-maine-bidd.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>321 Elm St. Biddeford, ME 04005</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-biddeford-me">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->  
            <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MAINE - <strong>South Portland</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-maine-sopo.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>331 Lincoln Street South Portland, ME 04106</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-south-portland-me">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->
      </ng-container>

      <!-- NEW HAMPSHIRE -->
      <ng-container *ngIf="showNH">
            <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2 photography">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">NEW HAMPSHIRE - <strong>Rochester</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-nh-rochester.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>115 Highland St Rochester, NH 03868</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-rochester-nh">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2 development">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">NEW HAMPSHIRE - <strong>Portsmouth - Seacoast</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-nh-portsmouth.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>2800 Heritage Ave, Unit 17 Portsmouth, NH 03801</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-portsmouth-nh">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->

      </ng-container>

      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

