import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-book-appointment',
  templateUrl: './page-book-appointment.component.html',
  styleUrls: ['./page-book-appointment.component.css']
})
export class PageBookAppointmentComponent implements OnInit {

  @ViewChild('targetDiv') targetDiv!: ElementRef<HTMLDivElement>;
  navClass = 'nav-light';
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';

  showMaine = false;
  showNH = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  bookAppointment(loc:string) {
    switch(loc){ 
      case "me-sopo":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/KFRYT3B1P3DT9/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;
      case "me-bidd":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/L0MZ4NXAHZCTH/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;
      case "nh-roch":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/LXBM97TC2A0CE/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;
      case "nh-ports":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/LXSEP2XHTD1T5/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;
      case "zoom":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/LCY16T44NNQ2X/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;    
      default:
        break;
    }
  }

  filterLocations(loc:string) {
    switch ( loc ) {
       case 'me':
            this.showMaine = true;
            this.showNH = false;
           break;
       case 'ma':
            this.showMaine = false;
            this.showNH = false;
           break;
       case 'nh':
            this.showMaine = false;
            this.showNH = true;
           break;
       default: 
            this.showMaine = false;
            this.showNH = false;
           break;
    }
    this.targetDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }  

}
