<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark logo-light-mode" height="36" alt="">
        <img src="assets/images/logo-light.png" class="logo-dark-mode" height="36" alt="">
      </a>

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark" height="36" alt="">
        <img src="assets/images/logo-light.png" class="l-light" height="36" alt="">
      </a>
    </div>
    <!--end login button-->
    <div id="navigation" *ngIf="Menuoption == 'center'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">MONUMENTS</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-upright" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>upright monuments</a></li>
                <li><a routerLink="/gallery-monument-flat" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>flat monuments</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-slant" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>slant monuments</a></li>
                <li><a routerLink="/gallery-monument-bench" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>bench monuments</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-bronze" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>bronze monuments</a></li>
                <li><a routerLink="/gallery-monument-custom" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>custom monuments</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-cremation" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>cremation monuments</a></li>
                <li><a routerLink="/gallery-monument-civic-jobs" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>Civic jobs</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-mausoleums" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>Mausoleums</a></li>
              </ul>
            </li>            
          </ul>
        </li>
        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">LOCATIONS</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <ul>
                <li><a class="nav-link-ref" routerLink="/contact-biddeford-me"> Biddeford - me</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-south-portland-me"> South Portland - me</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-rochester-nh">ROCHESTER - nh</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-portsmouth-nh">PORTSMOUTH - nh</a></li>                
              </ul>
            </li>
          </ul>
        </li>
        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">SERVICES</a><span class="menu-arrow"></span>
          <ul class="submenu">           
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-onsite-engraving" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>Onsite Engraving</a></li>
                <li><a routerLink="/gallery-monument-onsite-cleaning" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>Onsite Cleaning</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li><a routerLink="/page-book-appointment" class="nav-link-ref">SCHEDULE APPT.</a></li>
        <li><a routerLink="/referral-in-home" class="nav-link-ref">REFERRAL</a></li>
      </ul>
      <!--end navigation menu-->
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->