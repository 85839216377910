<!-- Start -->
<section class="bg-half-170 d-table bg-primary w-100" id="home"
    style="background-image: url('assets/images/calendar.jpg');">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col">
                <div class="pages-heading title-heading text-center">
                    <h4 class="display-6 text-white title-dark mb-4 fw-bold">Schedule an Appointment</h4>
                    <p class="text-white-50 mx-auto">Richardson Monument Company, Inc. offers honest and reasonable quotes. We guarantee our work and can provide you with a certificate of monument quality if needed. In addition, we ensure that the monument is delivered and installed to the specifications required by the cemetery of choice.</p>
                    <p class="text-white-50 mx-auto">Use our chat or choose a location below to schedule time with our monument specialist! We guarantee delivery and installation in your cemetery. For three generations, we have been proud of our work and have helped families make the right decision when choosing a monument or marker that will last a lifetime.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Start -->
<section class="section">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="features-absolute">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" (click)="filterLocations('me')" style="cursor: pointer;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">MAINE</a></h5>
                  <p class="text-muted">2 - Locations</p>

                  <a href="javascript:void(0)" class="text-primary">VIEW LOCATIONS<i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" (click)="filterLocations('nh')" style="cursor: pointer;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">NEW HAMPSHIRE</a></h5>
                  <p class="text-muted">2 - Locations</p>

                  <a href="javascript:void(0)" class="text-primary">VIEW LOCATIONS<i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-12 col-md-12 col-12" style="margin-top:40px;cursor: pointer;" (click)="bookAppointment('zoom')">
              <div class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="background-color: #7F1517;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><span class="text-light">CANT MAKE IT IN PERSON. NO PROBLEM</span></h5>
                  <p class="text-light">Schedule a virtual design meeting via Zoom, <img src="assets/images/Zoom-Logo.png"> with one of our reps by clicking below.</p>
                  <span class="text-light">SCHEDULE HERE<i class="uil uil-angle-right-b align-middle"></i></span>
                </div>
              </div>
            </div>
            <!--end col-->            

          </div>
        </div>
      </div>
    </div>


    <div class="row projects-wrapper" #targetDiv style="margin-top: 40px;">
      

<!-- MAINE -->
<ng-container *ngIf="showMaine">
      <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2 designing">
        <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
          <div class="card-body p-0">
            <h6 class="mb-0">MAINE - <strong>Biddeford</strong></h6>
            <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
              <img src="assets/images/maps/richardson-maine-bidd.png" class="img-fluid" alt="work-image">
            </a>
            <div class="content bg-white p-3">
              <h5>321 Elm St. Biddeford, ME 04005</h5>
              <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="bookAppointment('me-bidd')">BOOK HERE</button>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->          
      <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2 branding">
        <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
          <div class="card-body p-0">
            <h6 class="mb-0">MAINE - <strong>South Portland</strong></h6>
            <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
              <img src="assets/images/maps/richardson-maine-sopo.png" class="img-fluid" alt="work-image">
            </a>
            <div class="content bg-white p-3">
              <h5>331 Lincoln Street South Portland, ME 04106</h5>
              <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="bookAppointment('me-sopo')">BOOK HERE</button>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
</ng-container>

<!-- NEW HAMPSHIRE -->
<ng-container *ngIf="showNH">
      <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2 photography">
        <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
          <div class="card-body p-0">
            <h6 class="mb-0">NEW HAMPSHIRE - <strong>Rochester</strong></h6>
            <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
              <img src="assets/images/maps/richardson-nh-rochester.png" class="img-fluid" alt="work-image">
            </a>
            <div class="content bg-white p-3">
              <h5>115 Highland St Rochester, NH 03868</h5>
              <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="bookAppointment('nh-roch')">BOOK HERE</button>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2 development">
        <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
          <div class="card-body p-0">
            <h6 class="mb-0">NEW HAMPSHIRE - <strong>Portsmouth - Seacoast</strong></h6>
            <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
              <img src="assets/images/maps/richardson-nh-portsmouth.png" class="img-fluid" alt="work-image">
            </a>
            <div class="content bg-white p-3">
              <h5>2800 Heritage Ave, Unit 17 Portsmouth, NH 03801</h5>
              <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="bookAppointment('nh-ports')">BOOK HERE</button>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
</ng-container>




      <!--end col-->      
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->


<!-- Back to top -->
<!-- <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a> -->
<!-- Back to top -->
